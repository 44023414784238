import React, { FC } from "react";
import { PageProps } from "gatsby";

import { PageHero, PageHeroProps } from "components";
import { DefaultLayout, DefaultLayoutProps } from "layouts";

const Page404: FC<PageProps> = () => {
  const page = "page-404";

  const layoutProps: DefaultLayoutProps = {
    on: page,
  };

  const heroProps: PageHeroProps = {
    caption: {
      heading: "404: Page Not Found",
      subheading: "Don't worry, we all get a little lost from time to time.",
    },
    on: page,
  };

  return (
    <DefaultLayout {...layoutProps}>
      <PageHero {...heroProps} />
    </DefaultLayout>
  );
};

export default Page404;
